import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.addEventListeners();
  }

  disconnect() {
    this.removeEventListeners();
  }

  addEventListeners() {
    this.element.addEventListener("input", this.formatSSN);
  }

  removeEventListeners() {
    this.element.removeEventListener("input", this.formatSSN);
  }

  formatSSN = () => {
    let value = this.element.value.replace(/\D/g, "");

    if (value.length > 3 && value.length <= 5) {
      value = value.slice(0, 3) + "-" + value.slice(3);
    } else if (value.length > 5) {
      value = value.slice(0, 3) + "-" + value.slice(3, 5) + "-" + value.slice(5, 9);
    }
    this.element.value = value;
  };
}
