import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["periodType", "periodCount", "customDate", "customStartDate", "customEndDate"];

  connect() {
    this.periodTypeTarget.addEventListener("change", this.changePeriodType.bind(this));

    this.changePeriodType({ target: this.periodTypeTarget });
  }

  changePeriodType(event) {
    const periodType = event.target.value;

    this.customDateTargets.forEach((customDateTarget) => {
      customDateTarget.parentElement.classList.toggle("hidden", periodType !== "Custom");

      periodType === "Custom" ? (customDateTarget.required = true) : (customDateTarget.required = false);
    });
  }

  export(event) {
    event.preventDefault();

    const baseUrl = this.data.get("export-url");
    const customStartDate = this.customStartDateTarget.value;
    const customEndDate = this.customEndDateTarget.value;

    if (baseUrl && baseUrl !== "null") {
      let queryParams = new URLSearchParams({
        custom_start_date: customStartDate,
        custom_end_date: customEndDate,
      }).toString();

      window.location.href = `${baseUrl}?${queryParams}`;
    } else {
      console.error("Export URL is null or undefined.");
    }
  }
}
