import { Controller } from "stimulus";
import { v4 as uuidv4 } from "uuid";

export default class extends Controller {
  static targets = ["itemInput", "itemsContainer", "itemTemplate"];

  static values = {
    itemTypes: Object,
    itemTypesInverted: Object,
  };

  connect() {
    this.booleanFields = ["required", "partial_submit", "other_enabled"];
    this.jsonFields = ["options"];

    this.setInitialValues();
    this.setItemsNumeration();
  }

  disconnect() {
    this.removeChangeEventListeners();
  }

  addChangeEventListeners() {
    this.getAllInputs().forEach((input) =>
      input.addEventListener("change", this.inputChanged)
    );
  }

  removeChangeEventListeners() {
    this.getAllInputs().forEach((input) =>
      input.removeEventListener("change", this.inputChanged)
    );
  }

  setInitialValues() {
    const items =  JSON.parse(this.itemInputTarget.value);

    if (items) {
      items.forEach((item) => this.setItem(item));
      this.addChangeEventListeners();
    }
  }

  setItem(item) {
    const itemElement = this.createItem();
    const itemType = this.itemTypesInvertedValue[item.item_type];
    this.setItemType(itemElement, itemType);
    this.setItemOptionsFields(itemElement, item);
    itemElement.querySelector("[name='item_type']").value = itemType;
    this.setLabelForRequiredField(itemElement);
  }

  createItem() {
    this.itemsContainerTarget.insertAdjacentHTML(
      "beforeend",
      this.itemTemplateTarget.innerHTML
    );

    return this.itemsContainerTarget.lastElementChild;
  }

  setItemOptionsFields(itemElement, item) {
    const options = item.options;

    Object.keys(options).forEach((field) => {
      const value = options[field];
      const input = itemElement.querySelector(`[data-field="${field}"]`);
      this.parseValue(input, value);
    });
  }

  parseValue(input, value) {
    const field = input.dataset.field;

    if (this.booleanFields.includes(field)) {
      input.checked = value;
    } else if (this.jsonFields.includes(field)) {
      input.value = JSON.stringify(value);
    } else {
      input.value = value;
    }
  }

  inputChanged = () => {
    const items = [];

    this.getItemsElements().forEach((item, index) => {
      const itemObject = this.createItemObject(item, index);
      items.push(itemObject);
    });

    this.itemInputTarget.value = JSON.stringify(items);
  };

  createItemObject(item, index) {
    const type = item.querySelector("[name='item_type']").value;

    return {
      item_type: this.itemTypesValue[type],
      item_number: index + 1,
      options: this.createItemObjectOptions(item),
    };
  }

  createItemObjectOptions(item) {
    const inputs = this.getItemInputs(item);
    const options = {};
    inputs.forEach((input) => {
      if (input.dataset.field)
        options[input.dataset.field] = this.parseInputValue(input);
    });
    return options;
  }

  parseInputValue(input) {
    if (this.booleanFields.includes(input.dataset.field)) {
      return input.checked;
    } else if (this.jsonFields.includes(input.dataset.field)) {
      return JSON.parse(input.value);
    } else {
      return input.value;
    }
  }

  getAllInputs() {
    const items = this.getItemsElements();
    const inputs = [];
    items.forEach((item) =>
      this.getItemInputs(item).forEach((input) => inputs.push(input))
    );
    return inputs;
  }

  getItemsElements() {
    return this.itemsContainerTarget.children;
  }

  getItemInputs(item) {
    return item.querySelectorAll("input");
  }

  addItem() {
    this.removeChangeEventListeners();
    const item = this.createItem();
    this.setItemType(item, "text_input");
    this.addChangeEventListeners();
    this.setItemsNumeration();
    this.setId(item);
    this.setLabelForRequiredField(item);
  }

  removeItem(e) {
    this.removeChangeEventListeners();

    const button = e.target;
    const parentElement = button.closest("[data-element='item']");
    parentElement.remove();
    this.inputChanged();

    this.addChangeEventListeners();
    this.setItemsNumeration();
  }

  setItemsNumeration(){
    this.getItemsElements().forEach((item, index) => {
      item.querySelector("[data-element='itemNumber']").innerText = index + 1;
    });
  }

  setId(item){
    const id = item.querySelector("[data-field='id']");
    id.value = uuidv4();
  }

  setLabelForRequiredField(item){
    const field = item.querySelector("[data-field='required']");
    const label = field.nextElementSibling;
    field.id = uuidv4();
    label.setAttribute('for', field.id);
  }

  inputTypeTemplate(inputType) {
    return this.element.querySelector(`[data-input-type="${inputType}"]`)
      .innerHTML;
  }

  changeInputType(e) {
    const selectElement = e.target;
    this.removeChangeEventListeners();
    this.setItemType(selectElement.closest("[data-element='item']"), selectElement.value);
    this.addChangeEventListeners();
  }

  setItemType(item, item_type = "text_input") {
    const container = item.querySelector("[data-element='fieldsContainer']");
    container.innerHTML = this.inputTypeTemplate(item_type);
  }

  resetAllEventListeners() {
    this.removeChangeEventListeners();
    this.addChangeEventListeners();
  }
}
