import { Controller } from "stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["select"];

  selectTargetConnected(element) {
    this.initializeTomSelect(element);
  }

  initializeTomSelect(selectTarget) {
    const optionsData = JSON.parse(selectTarget.getAttribute("data-options-data"));
    const addPrecedence = selectTarget.getAttribute("data-tom-select-add-precedence") === "true";
    const addRemoveButton = selectTarget.getAttribute("data-tom-select-remove-button") === "true";
    const hasGroupedOptions =
      Array.isArray(optionsData) &&
      optionsData.every((group) => typeof group === "object" && group.hasOwnProperty("options"));

    const canCreate = selectTarget.getAttribute("data-tom-select-create") === "true";

    const baseOptions = {
      hideSelected: selectTarget.getAttribute("data-tom-select-hide-selected") === "true",
      addPrecedence: addPrecedence || false,
      plugins: addRemoveButton ? ["remove_button"] : [],
      searchField: JSON.parse(selectTarget.getAttribute("data-tom-select-search-field") || "[]"),
      sortField: [{ field: "$order" }, { field: "$score" }],
    };

    if (canCreate) {
      baseOptions.create = this.createItem.bind(this, selectTarget);
    }

    let tomSelectOptions;

    if (hasGroupedOptions) {
      tomSelectOptions = {
        ...baseOptions,
        optgroups: optionsData.map((group) => ({
          label: group.label,
          value: group.label,
        })),
        options: optionsData.flatMap((group, groupIndex) =>
          group.options
            .filter((option) => option.value !== "request_additional_data")
            .map((option, optionIndex) => ({
              text: option.text,
              value: option.value,
              optgroup: group.label,
              $order: groupIndex * 100 + optionIndex + 1,
            }))
        ),
        render: {
          optgroup_header: function (data, escape) {
            const group = optionsData.find((group) => group.label === data.label);
            return `
              <div class="optgroup-header">
                ${group.icon ? `<i class="${group.icon}"></i>` : ""}
                <h3>${data.label}</h3>
                ${group.count !== undefined ? `<span class="optgroup-count">${group.count}</span>` : ""}
              </div>
            `;
          },
          option: function (data, escape) {
            return `<div class="option">${data.text}</div>`;
          },
          option_create: function (data, escape) {
            return `<div class="create">Add new...</div>`;
          },
        },
      };
    } else {
      tomSelectOptions = {
        ...baseOptions,
        options: optionsData
          .filter((option) => option.value !== "request_additional_data")
          .map((option, optionIndex) => ({
            text: option.text,
            value: option.value,
            description: option.description,
            icon: option.icon,
            $order: optionIndex + 1,
          })),
        render: {
          option: function (data, escape) {
            return `
          <div class="option">
            ${data.icon ? `<i class="${data.icon}"></i>` : ""}
            <div class="option-value">
              ${data.text}
              ${data.description ? `<p class="description">${data.description}</p>` : ""}
            </div>
          </div>
        `;
          },
        },
      };
    }

    const tomSelect = new TomSelect(selectTarget, tomSelectOptions);
    selectTarget.tomSelect = tomSelect;

    if (hasGroupedOptions) {
      tomSelect.on("type", () => {
        setTimeout(() => {
          this.updateOptionCounts(selectTarget);
        }, 0);
      });
    }
  }

  updateOptionCounts(selectTarget) {
    const tomSelect = selectTarget.tomSelect;

    if (tomSelect && tomSelect.optgroups) {
      const optgroups = Object.values(tomSelect.optgroups);

      optgroups.forEach((optgroup) => {
        const optgroupElement = tomSelect.dropdown_content.querySelector(`[data-group="${optgroup.value}"]`);

        if (optgroupElement) {
          const visibleOptions = Array.from(optgroupElement.querySelectorAll(".option")).filter(
            (option) => option.style.display !== "none"
          );

          const countElement = optgroupElement.querySelector(".optgroup-count");

          if (countElement) {
            countElement.textContent = visibleOptions.length;
          }
        }
      });
    }
  }

  createItem(selectTarget, input, callback) {
    const optionGroupMappings = selectTarget.optionGroupMappings;
    const createUrl = selectTarget.getAttribute("data-tom-select-create-url");

    if (createUrl) {
      let counterpartyType = "";

      if (optionGroupMappings) {
        const optionGroup = this.getOptionGroup(selectTarget, input);

        if (optionGroup !== "") {
          const selectedOptionGroup = optionGroupMappings.find((group) => group[0] === optionGroup);
          if (selectedOptionGroup && selectedOptionGroup[1] && selectedOptionGroup[1].options) {
            const matchingOption = selectedOptionGroup[1].options.find((option) => option.text.trim() === input.trim());
            if (matchingOption) {
              counterpartyType = matchingOption.counterparty_type;
            }
          }
        }
      }

      document.getElementById("newEntityName").value = input;

      if (counterpartyType) {
        document.getElementById("newEntityCounterpartyType").value = counterpartyType;
      } else {
        document.getElementById("newEntityCounterpartyType").value = "";
      }

      const transactionId = new URLSearchParams(createUrl.split("?")[1]).get("transaction_id");
      const newModalController = document.querySelector('[data-controller="new-modal"]');
      if (newModalController) {
        const newModalInstance = this.application.getControllerForElementAndIdentifier(newModalController, "new-modal");
        if (newModalInstance && typeof newModalInstance.showModal === "function") {
          newModalInstance.showModal("counterpartyTypeModal", transactionId);
        }
      }

      callback(null);
    } else {
      console.warn("newModalInstance or showModal function not found");
      callback({ value: input, text: input });
    }
  }

  getOptionGroup(selectTarget, input) {
    const optionGroupMappings = selectTarget.optionGroupMappings;
    if (optionGroupMappings) {
      for (const [groupName, groupData] of optionGroupMappings) {
        if (groupData.options.some((option) => option.text.trim() === input.trim())) {
          return groupName;
        }
      }
    }
    return "";
  }
}
