import { Controller } from "stimulus";
import consumer from "~/channels/consumer";
import { get, put } from "@rails/request.js";
import tippy from "tippy.js";
import { uniq, debounce, reduce } from "~/utils/lodashish";

export default class extends Controller {
  static targets = ["group", "column", "tippyLoadingTemplate", "absoluteViewBody", "emptyView"];
  static values = {
    id: String,
    userDisplayId: String,
  };

  initialize() {
    this._batchProspectUpdate = [];
    this._batchGroupUpdate = [];
    this._batchGroupTotalsUpdate = [];

    this.debouncedBatchProspectUpdate = debounce(this.batchProspectUpdate, 400).bind(this);
    this.debounceGroupUpdate = debounce(this.groupUpdate, 300).bind(this);
    this.debounceGroupTotalsUpdate = debounce(this.groupTotalsUpdate, 300).bind(this);
    this.debouncePipelineUpdate = debounce(this.pipelineUpdate, 300).bind(this);
    this.debounceShowOrHideEmptyView = debounce(this.showOrHideEmptyView, 700).bind(this);

    this.scheduleGroupUpdate("*");
  }

  connect() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: "PipelineChannel",
        pipeline_id: this.idValue,
        pipeline_user_display_id: this.userDisplayIdValue,
      },
      {
        received: this.received.bind(this),
      }
    );

    this.startResizeObserver();
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
    this.stopResizeObserver();
  }

  startResizeObserver() {
    if (!document.getElementById("pipeline_user_display")) return;

    this._resizeObserver = this._resizeObserver || new ResizeObserver(this.observeResize.bind(this));
    this._resizeObserver.observe(document.getElementById("pipeline_user_display"));
  }

  stopResizeObserver() {
    if (!this._resizeObserver) return;

    this._resizeObserver.disconnect();
  }

  observeResize() {
    this.absoluteViewBodyTarget.style.width = `${document.getElementById("pipeline_user_display").clientWidth}px`;
  }

  async updateUserDisplay(payload) {
    const url = `/pipelines/${this.idValue}/update_user_display`;
    this.dispatchRequestStartEvent();
    const response = await put(url, {
      responseKind: "turbo-stream",
      body: payload,
    });
    this.dispatchRequestEndEvent();
  }

  columnControllers() {
    return this.columnTargets.map((columnTarget) => {
      return this.application.getControllerForElementAndIdentifier(columnTarget, "pipelines--pipeline-column");
    });
  }

  filterColumnControllers() {
    return this.columnControllers().filter((column) => {
      return column.hasFilter();
    });
  }

  largeCurrencyColumnController() {
    const investmentColumnTarget = this.columnTargets.find((columnTarget) => {
      return columnTarget.dataset["pipelines-PipelineColumnCellTypeValue"] === "large_currency_cell";
    });

    if (!investmentColumnTarget) {
      return null;
    }

    return this.application.getControllerForElementAndIdentifier(investmentColumnTarget, "pipelines--pipeline-column");
  }

  groupControllers() {
    return this.groupTargets.map((groupTarget) => {
      return this.application.getControllerForElementAndIdentifier(groupTarget, "pipelines--pipeline-group");
    });
  }

  groupController(name) {
    return this.groupControllers().find((group) => {
      return group.groupnameValue === name;
    });
  }

  rowController(prospectId) {
    const rowTarget = this.element.querySelector(`[data-pipelines--pipeline-row-id-value="${prospectId}"]`);

    return this.application.getControllerForElementAndIdentifier(rowTarget, "pipelines--pipeline-row");
  }

  async showTippy(event) {
    const target = event.currentTarget;
    const trigger = target.dataset.tippyTrigger || "click";
    const delay = trigger === "mouseenter" ? 500 : 0;

    if (target._tippy) {
      target._tippy.show();
      return;
    }

    target.dataset.action = "";

    const hideOnEsc = {
      name: "hideOnEsc",
      defaultValue: true,
      fn({ hide }) {
        function onKeyDown(event) {
          if (event.keyCode === 27) {
            hide();
          }
        }

        return {
          onShow() {
            document.addEventListener("keydown", onKeyDown);
          },
          onHide() {
            document.removeEventListener("keydown", onKeyDown);
          },
        };
      },
    };

    const autofocus = this.autofocusElement.bind(this);
    const tippyInstance = tippy(target, {
      appendTo: () => document.body,
      allowHTML: true,
      interactive: true,
      arrow: false,
      theme: "custom",
      maxWidth: "none",
      offset: [0, 0],
      delay: delay,
      content: this.tippyLoadingTemplateTarget.innerHTML,
      placement: target.dataset.tippyPlacement || "bottom-start",
      hideOnClick: true,
      showOnCreate: true,
      trigger: trigger,
      plugins: [hideOnEsc],
      onShown(instance) {
        autofocus(instance.popper);
        document.addEventListener("turbo:before-fetch-response", (event) => {
          if (event.detail.fetchResponse.response.headers.get("X-Keep-Tippy-Open")) return;

          instance.hide();
        });
      },
    });

    const response = await get(target.dataset.url);

    if (response.ok) {
      const body = await response.text;
      tippyInstance?.setContent(body);
    }
  }

  autofocusElement(target) {
    document.activeElement.blur();
    const autofocusElement = target.querySelector("[autofocus]");
    if (autofocusElement) {
      autofocusElement.focus();
    }
  }

  invlaidateFilters() {
    this.columnControllers().forEach((column) => {
      column?.invalidateFilterDropdown();
    });
  }

  showOrHideEmptyView() {
    const rowTargets = this.element.querySelectorAll(`[data-controller="pipelines--pipeline-row"]`);

    if (rowTargets.length < 1) {
      this.emptyViewTarget.classList.remove("hidden");
      this.absoluteViewBodyTarget.classList.add("hidden");
    } else {
      this.emptyViewTarget.classList.add("hidden");
      this.absoluteViewBodyTarget.classList.remove("hidden");
    }
  }

  checkedRowsCount() {
    return reduce(
      this.groupControllers(),
      (sum, group) => {
        return sum + group.checkedRowsCount();
      },
      0
    );
  }

  emptyGroupTotalsNames() {
    return this.groupControllers()
      .filter((group) => {
        return group?.totalIsEmpty();
      })
      .map((group) => {
        return group.groupnameValue;
      });
  }

  async groupUpdate() {
    const encodedGroupNames = uniq(this._batchGroupUpdate)
      .map((groupName) => {
        this._batchGroupTotalsUpdate.push(groupName);
        return encodeURIComponent(groupName);
      })
      .join("||");
    this._batchGroupUpdate = [];

    if (encodedGroupNames === "") {
      return;
    }

    this.dispatchRequestStartEvent();

    if (this._groupUpdateAbortController) {
      try {
        this._groupUpdateAbortController.abort();
      } catch (e) {
        if (e.name !== "AbortError") {
          throw e;
        }

        console.log("Aborted group update");
      }
    }

    this._groupUpdateAbortController = new AbortController();
    const url = `/pipelines/${this.idValue}/group_parts?name=${encodedGroupNames}&remove_skeleton=y`;
    await get(url, {
      responseKind: "turbo-stream",
      signal: this._groupUpdateAbortController.signal,
    });

    this.dispatchRequestEndEvent();
    this._groupUpdateAbortController = null;
    this.invlaidateFilters();

    setTimeout(() => {
      this.debounceShowOrHideEmptyView();

      this._batchGroupTotalsUpdate = this._batchGroupTotalsUpdate.concat(this.emptyGroupTotalsNames());
      this.debounceGroupTotalsUpdate();
    }, 500);
  }

  scheduleGroupUpdate(groupName) {
    this._batchGroupUpdate.push(groupName);
    this.debounceGroupUpdate();
  }

  async pipelineUpdate() {
    this.dispatchRequestStartEvent();

    await get(`/pipelines/${this.idValue}/pipeline_parts`, {
      responseKind: "turbo-stream",
    });
    this.dispatchRequestEndEvent();
    this.invlaidateFilters();
    this.debounceShowOrHideEmptyView();
  }

  async groupTotalsUpdate(groupName, skipEncoding) {
    const encodedGroupNames = uniq(this._batchGroupTotalsUpdate)
      .map((groupName) => {
        return encodeURIComponent(groupName);
      })
      .join("||");
    this._batchGroupTotalsUpdate = [];

    if (encodedGroupNames === "") {
      return;
    }

    this.dispatchRequestStartEvent();

    const url = `/pipelines/${this.idValue}/group_counts?name=${encodedGroupNames}`;

    await get(url, { responseKind: "turbo-stream" });
    this.dispatchRequestEndEvent();
    this.groupControllers().forEach((group) => {
      group?.debounceRefreshGroupHeadline();
    });

    this.headlinePartsUpdate();
  }

  async batchProspectUpdate() {
    const url = `/pipelines/${this.idValue}/row_parts?pipeline_action=update&prospect_ids=${uniq(
      this._batchProspectUpdate
    )}`;
    this._batchProspectUpdate = [];

    this.dispatchRequestStartEvent();

    await get(url, { responseKind: "turbo-stream" });
    this.dispatchRequestEndEvent();
    this.invlaidateFilters();

    setTimeout(() => {
      this.debounceShowOrHideEmptyView();

      this._batchGroupTotalsUpdate = this._batchGroupTotalsUpdate.concat(this.emptyGroupTotalsNames());
      this.debounceGroupTotalsUpdate();
    }, 500);
  }

  async batchProspectCreate(prospectId) {
    const url = `/pipelines/${this.idValue}/row_parts?pipeline_action=create&prospect_ids=${prospectId}`;

    this.dispatchRequestStartEvent();

    await get(url, { responseKind: "turbo-stream" });
    this.dispatchRequestEndEvent();
    this.invlaidateFilters();
    this.debounceShowOrHideEmptyView();

    this._batchGroupTotalsUpdate.push("*");
    this.debounceGroupTotalsUpdate();
  }

  async headlinePartsUpdate() {
    this.dispatchRequestStartEvent();
    await get(`/pipelines/${this.idValue}/headline_parts`, {
      responseKind: "turbo-stream",
    });
    this.dispatchRequestEndEvent();
    this.invlaidateFilters();
  }

  dispatchRequestStartEvent() {
    document.dispatchEvent(
      new CustomEvent("network-request:start", {
        bubbles: true,
        detail: { pipeline: this },
      })
    );
  }

  dispatchRequestEndEvent() {
    document.dispatchEvent(
      new CustomEvent("network-request:end", {
        bubbles: true,
        detail: { pipeline: this },
      })
    );
  }

  async received(data) {
    this._handlePipelineChange(data);
    this._handleProspectChange(data);
    this._handleUserDisplayChange(data);
  }

  _handlePipelineChange(data) {
    const { action, model, id, changes } = data;

    if (model !== "pipeline") return;

    this.debouncePipelineUpdate();
  }

  _handleProspectChange(data) {
    const { action, model, id } = data;

    if (model !== "pipeline_prospect") return;

    const groupNamesToUpdate = [];

    if (action === "discard") {
      this.groupControllers().forEach((group) => {
        if (group.removeRow(id)) {
          this._batchGroupTotalsUpdate.push(group.groupnameValue);
        }
      });
      this.debounceGroupTotalsUpdate();
    } else if (action === "create") {
      const noProspectsViewIsVisible = this.groupControllers().length === 0;
      if (noProspectsViewIsVisible) {
        this.debouncePipelineUpdate();
        return;
      }

      this.batchProspectCreate(id);
    } else {
      this._batchProspectUpdate.push(id);
      this.debouncedBatchProspectUpdate();

      const rowController = this.rowController(id);

      if (rowController) {
        this._batchGroupTotalsUpdate.push(rowController.groupController().groupnameValue);
      }
    }
  }

  _handleUserDisplayChange(data) {
    const { action, model, id, changes } = data;

    if (model !== "pipeline_user_display") return;

    // If a text input is focused, it could be a header search and we do not want to reload to remove focus
    if (
      document.activeElement &&
      document.activeElement.tagName === "INPUT" &&
      !changes.hasOwnProperty("hide_empty_groups")
    ) {
      this.scheduleGroupUpdate("*");
    } else {
      this.debouncePipelineUpdate();
    }
  }
}
