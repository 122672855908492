import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "whoSelect",
    "whatSelect",
    "counterpartyType",
    "accountColumn",
    "counterpartyId",
    "submitButton",
    "submitButtonText",
    "submitAction",
    "pendingColumn",
    "pipelineStageIdColumn",
  ];

  connect() {
    this.checkFormValidity();
    this.setInitialCounterparty();
    this.whoSelectTarget.addEventListener("change", () => {
      this.setCounterparty();
      this.updateWhatOptions();
      this.checkFormValidity();
    });

    this.whatSelectTarget.addEventListener("change", () => {
      this.setAccountId();
      this.checkFormValidity();
    });

    document.addEventListener("newEntityCreated", this.handleNewEntityCreated.bind(this));
  }

  setInitialCounterparty() {
    const counterpartyId = this.counterpartyIdTarget.value;
    const counterpartyType = this.counterpartyTypeTarget.value;

    if (counterpartyId && counterpartyType) {
      const counterpartyTypeAbbreviation = this.getCounterpartyTypeAbbreviation(counterpartyType);
      const initialValue = `${counterpartyTypeAbbreviation}_${counterpartyId}`;
      this.whoSelectTarget.value = initialValue;

      // Wait for the tomSelect instance to be available before setting the value
      setTimeout(() => {
        if (this.whoSelectTarget.tomSelect) {
          this.whoSelectTarget.tomSelect.setValue(initialValue);
        }
      }, 0);
    }
  }

  setCounterparty() {
    const selectedValue = this.whoSelectTarget.value;

    if (!selectedValue) {
      return;
    }

    const valueParts = selectedValue.split("_");

    if (valueParts.length === 2) {
      const counterpartyType = valueParts[0];
      const counterpartyId = valueParts[1];

      this.counterpartyIdTarget.value = counterpartyId;
      this.counterpartyTypeTarget.value = this.getCounterpartyTypeClass(counterpartyType);
    } else {
      this.counterpartyIdTarget.value = "";
      this.counterpartyTypeTarget.value = "";
    }
  }

  getCounterpartyTypeAbbreviation(counterpartyType) {
    switch (counterpartyType) {
      case "FirmAdmin::PortfolioCompany":
        return "pc";
      case "FirmAdmin::CapitalAccount":
        return "ca";
      case "Organization":
        return "org";
      case "FirmAdmin::AccountingEntity":
        return "ae";
      default:
        return "";
    }
  }

  getCounterpartyTypeClass(counterpartyType) {
    switch (counterpartyType) {
      case "pc":
        return "FirmAdmin::PortfolioCompany";
      case "ca":
        return "FirmAdmin::CapitalAccount";
      case "org":
        return "Organization";
      case "ae":
        return "FirmAdmin::AccountingEntity";
      default:
        return "";
    }
  }

  setAccountId() {
    const selectedOption = this.whatSelectTarget.value;
    const isRequestAdditionalData = selectedOption.startsWith("request_additional_data_");

    if (!isRequestAdditionalData) {
      const accountId = selectedOption;
      const isDebit = this.determineAccountType();
      const accountField = isDebit
        ? "firm_admin_accounting_transaction[firm_admin_account_debit_id]"
        : "firm_admin_accounting_transaction[firm_admin_account_credit_id]";
      this.accountColumnTarget.name = accountField;
      this.accountColumnTarget.value = accountId;
    } else {
      // Set the data_request_action field when a request additional data option is selected
      this.accountColumnTarget.name = "firm_admin_accounting_transaction[data_request_action]";
      this.accountColumnTarget.value = selectedOption;
    }
  }

  determineAccountType() {
    return this.accountColumnTarget.name === "firm_admin_accounting_transaction[firm_admin_account_debit_id]";
  }

  updateSubmitButtonText(buttonText) {
    this.submitButtonTextTarget.textContent = buttonText;
  }

  checkFormValidity() {
    const isValid = this.whoSelectTarget.value && this.whatSelectTarget.value;
    this.submitButtonTarget.disabled = !isValid;
  }

  setDataRequested() {
    const selectedOption = this.whatSelectTarget.value;
    const isRequestAdditionalData = selectedOption.startsWith("request_additional_data_");

    if (isRequestAdditionalData) {
      const pipelineStageId = selectedOption.split("_")[3];
      this.pendingColumnTarget.value = "true";
      this.pipelineStageIdColumnTarget.value = pipelineStageId;
      this.accountColumnTarget.name = "firm_admin_accounting_transaction[data_request_action]";
      this.accountColumnTarget.value = selectedOption;

      const creditIdField = document.querySelector(
        'input[name="firm_admin_accounting_transaction[firm_admin_account_credit_id]"]'
      );
      const debitIdField = document.querySelector(
        'input[name="firm_admin_accounting_transaction[firm_admin_account_debit_id]"]'
      );

      if (creditIdField) {
        creditIdField.value = null;
      }
      if (debitIdField) {
        debitIdField.value = null;
      }
    } else {
      this.pendingColumnTarget.value = "";
      this.pipelineStageIdColumnTarget.value = "";
    }

    this.updateSubmitAction(isRequestAdditionalData);
    this.updateSubmitButtonText(isRequestAdditionalData ? "Send Request" : "Reconcile");
  }

  updateSubmitAction(isRequestAdditionalData) {
    this.submitActionTarget.value = isRequestAdditionalData ? "send_request" : "reconcile";
  }

  updateWhatOptions() {
    const selectedWhoOption = this.whoSelectTarget.value;
    const whatSelect = this.whatSelectTarget.tomSelect;

    if (whatSelect) {
      const optionsData = JSON.parse(this.whatSelectTarget.getAttribute("data-options-data"));
      const requestDataOptions = optionsData.find((group) => group.label === "Request Additional Data")?.options || [];

      if (selectedWhoOption && requestDataOptions.length > 0) {
        const valueParts = selectedWhoOption.split("_");
        if (valueParts.length === 2 || valueParts.length === 3) {
          const counterpartyType = valueParts[0];
          if (counterpartyType === "org" || counterpartyType === "pc" || counterpartyType === "accounting") {
            requestDataOptions.forEach((option) => {
              const existingOption = whatSelect.options[option.value];
              if (!existingOption) {
                whatSelect.addOption(option);
              }
            });
          } else {
            requestDataOptions.forEach((option) => {
              whatSelect.removeOption(option.value);
            });
          }
        } else {
          requestDataOptions.forEach((option) => {
            whatSelect.removeOption(option.value);
          });
        }
      } else {
        requestDataOptions.forEach((option) => {
          whatSelect.removeOption(option.value);
        });
      }

      whatSelect.refreshOptions(false);
    }
  }

  handleNewEntityCreated(event) {
    const data = event.detail;
    const transactionId = data.transactionId;
    const whatSelect = document.querySelector(
      `[data-transaction-id="${transactionId}"] [data-firm-admin--accounting-transactions--reconcile-target="whatSelect"]`
    ).tomSelect;

    if (whatSelect) {
      Object.values(whatSelect.options)
        .filter((opt) => typeof opt.value === "string" && opt.value.startsWith("request_additional_data_"))
        .forEach((opt) => whatSelect.removeOption(opt.value));

      data.request_data_options.forEach((group) => {
        group.options.forEach((option) => {
          whatSelect.addOption(option);
        });
      });

      whatSelect.refreshOptions(false);
    }
  }
}
