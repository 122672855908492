import { Controller } from "stimulus";
import { post, get } from "@rails/request.js";
import Tagify from "@yaireo/tagify";
import { filter, includes } from "~/utils/lodashish";

export default class extends Controller {
  static targets = ["input", "results", "multipleRecipientsMessage", "primaryPerson", "bulkErrors"];
  static values = {
    changeTemplatePath: String,
    changeLoadingPath: String,
    emailRegex: String,
    person: String,
    bulkPeople: String,
    varsSet: Boolean,
    disableInput: Boolean,
  };
  static outlets = ["autocomplete-email"];

  tagify = null;
  activeItemIndex = -1;
  primaryRecipientIndex = -1;
  regexPattern = null;

  connect() {
    this.initializeTagify();
    this.dispatch("pollPeopleData");
    if (this.hasBulkPeopleValue) {
      this.populateBulkPeople();
    } else if (this.hasPersonValue) {
      this.prePopulatePerson();
    } else {
      this.prePopulateNoPerson();
    }
  }

  disconnect() {
    this.tagify && this.tagify.destroy();
  }

  prePopulatePerson() {
    const person = JSON.parse(this.personValue);
    this.tagify.addTags([
      {
        value: person.name + " <" + person.email + ">",
        email: person.email,
        id: person.id,
      },
    ]);
    const firstTagElement = this.tagify.getTagElms()[0];
    firstTagElement && firstTagElement.classList.add("primary");
    this.setPrimaryRecipient(0, person.id, this.varsSetValue);
  }

  prePopulateNoPerson() {
    const form = this.element.closest("form");
    const formData = new FormData(form);
    const templateId = formData.get("pipeline_email[email_template_id]");

    if (templateId.length > 0) {
      this.personalizeEmailBody();
    }
  }

  populateBulkPeople() {
    const jsonPersonId = this.hasPersonValue ? JSON.parse(this.personValue).id : null;

    JSON.parse(this.bulkPeopleValue).forEach((person) => {
      let cssClass = "";
      if (person.email === "invalid@invalid.inv") cssClass = "error";
      if (person.id === jsonPersonId) cssClass = "primary";
      this.tagify.addTags([
        {
          value: person.name + " <" + person.email + ">",
          email: person.email,
          id: person.id,
          prospect_id: person.prospect_id,
          class: cssClass,
        },
      ]);
      if (cssClass === "primary") this.setPrimaryRecipient(this.tagify.value.length - 1, person.id, this.varsSetValue);
    });
  }

  setBulkErrors(e) {
    const erroredPeopleIds = e.detail.peopleIds;

    this.tagify.getTagElms().forEach((tag) => {
      erroredPeopleIds.includes(parseInt(tag.id)) ? tag.classList.add("error") : tag.classList.remove("error");
    });
  }

  resultsTargetConnected(e) {
    if (!e.textContent) return;

    this.peopleData = JSON.parse(e.textContent);
    this.dispatch("copyPeopleData", {
      detail: { peopleData: this.peopleData },
    });
  }

  repopulatePeopleData(e) {
    if (!this.peopleData) return;
    this.dispatch("copyPeopleData", {
      detail: { peopleData: this.peopleData },
    });
  }

  setPeopleData(e) {
    this.peopleData = e.detail.peopleData;
  }

  initializeTagify() {
    const self = this;
    if (!this.inputTarget.classList.contains("tagified")) {
      this.tagify = new Tagify(this.inputTarget, {
        originalInputValueFormat: (valuesArr) => valuesArr.map((item) => item.email).join(","),
        duplicates: false,
        callbacks: {
          input: this.handleInput.bind(this),
          keydown: this.handleKeydown.bind(this),
          remove: this.handleRemoveTag.bind(this),
          click: this.updatePrimaryPerson.bind(this),
        },
        hooks: {
          beforePaste(event, data) {
            self.handleInput({ detail: { value: data.pastedText } });
          },
        },
        validate: this.hasEmailValue.bind(this),
        skipInvalid: true,
        pasteAsTags: false,
      });
      this.inputTarget.classList.add("tagified");
    }
    this.regexPattern = new RegExp(this.emailRegexValue);
  }

  hasEmailValue(e) {
    return !!e.email;
  }

  handleInput(e) {
    if (!this.peopleData || this.disableInputValue) return;

    const query = e.detail.value.split(",").pop().trim();
    if (query.length < 1) return this.hideResults();

    this.activeItemIndex = 0;
    this.renderResults(this.search(query));
  }

  updatePrimaryPerson(e) {
    if (
      this.inputTarget.name !== "pipeline_email[to]" ||
      e.detail.index === this.primaryRecipientIndex ||
      !this.hasBulkPeopleValue
    )
      return;
    const form = this.element.closest("form");
    form.querySelector('input[name="pipeline_email[prospect_id]"]').value = e.detail.data.prospect_id;
    this.replaceTagCssClass(this.primaryRecipientIndex, "");
    this.replaceTagCssClass(e.detail.index, "primary");
    this.setPrimaryRecipient(e.detail.index, e.detail.data.id);
  }

  hideResults() {
    this.resultsTarget.innerHTML = "";
    this.resultsTarget.classList.add("hidden");
  }

  addValidEmailInputToResults(results, query) {
    if (this.regexPattern.test(query) && results.filter((r) => r.email === query).length === 0) {
      results.push({ email: query, name: "New Person" });
    }
    return results;
  }

  handleRemoveTag(e) {
    if (this.hasBulkPeopleValue) {
      if (!this.inputTarget.name === "pipeline_email[to]") return false;

      const bulkIdElement = document.querySelector('input[name="pipeline_email[bulk_prospect_ids]"]');
      if (bulkIdElement) {
        bulkIdElement.value = bulkIdElement.value.replace(e.detail.data.prospect_id, "");
      }
    }

    if (e.detail.index === this.primaryRecipientIndex) {
      this.primaryRecipientIndex = -1;

      const form = this.element.closest("form");
      const prospectId = form.querySelector('input[name="pipeline_email[prospect_id]"]');
      if (prospectId) prospectId.value = "";

      const nextPrimaryIndex = this.tagify.value.findIndex((v) => !!v.id);
      if (nextPrimaryIndex > -1) {
        this.setPrimaryRecipient(nextPrimaryIndex, this.tagify.value[nextPrimaryIndex].id);
        this.replaceTagCssClass(nextPrimaryIndex, "primary");
        this.personalizeEmailBody();
      } else {
        this.multipleRecipientsMessageTarget.classList.add("hidden");
      }
    }
    if (this.tagify.value.length === 1) {
      this.multipleRecipientsMessageTarget.classList.add("hidden");
    }
  }

  handleKeydown(e) {
    switch (e.detail.event.key) {
      case "Enter":
      case "Tab":
        this.selectActiveEmail();
        break;
      case "ArrowDown":
        this.navigateSearchResults(1);
        break;
      case "ArrowUp":
        this.navigateSearchResults(-1);
        break;
      default:
        break;
    }
  }

  replaceTagCssClass(index, cssClass) {
    this.tagify.replaceTag(this.tagify.getTagElms()[index], {
      ...this.tagify.value[index],
      class: cssClass,
    });
  }

  search(query) {
    let results = filter(this.peopleData, (person) => {
      return (
        includes(person.name?.toLowerCase(), query.toLowerCase()) ||
        includes(person.email?.toLowerCase(), query.toLowerCase())
      );
    });
    return this.addValidEmailInputToResults(results, query);
  }

  renderResults(people) {
    this.resultsTarget.innerHTML = "";
    if (people.length === 0) {
      this.resultsTarget.classList.add("hidden");
      return;
    }

    this.resultsTarget.innerHTML = people.map((person, index) => this.resultHtml(person, index)).join("");

    this.resultsTarget.classList.remove("hidden");
  }

  resultHtml(person, index) {
    return `
    <div data-action="click->autocomplete-email#selectEmail"
         data-email="${person.email}"
         data-name="${person.name} &lt;${person.email}&gt;"
         ${person.id ? `data-person-id="${person.id}"` : ""}
         class="cursor-pointer px-3 py-2 hover:bg-gray-100 ${index == 0 ? "bg-gray-200" : ""}">
         ${person.name} &lt;${person.email}&gt;
    </div>
  `;
  }

  navigateSearchResults(direction) {
    const results = this.resultsTarget.children;
    if (!results.length) return;

    if (this.activeItemIndex >= 0) results[this.activeItemIndex].classList.remove("bg-gray-200");
    this.activeItemIndex += direction;
    if (this.activeItemIndex >= results.length) {
      this.activeItemIndex = 0;
    } else if (this.activeItemIndex < 0) {
      this.activeItemIndex = results.length - 1;
    }
    results[this.activeItemIndex].classList.add("bg-gray-200");
  }

  selectActiveEmail() {
    const activeItem = this.resultsTarget.children[this.activeItemIndex];
    if (activeItem) {
      this.addEmailTag(activeItem);
    }
  }

  selectEmail(event) {
    this.addEmailTag(event.currentTarget);
  }

  addEmailTag(target) {
    const displayValue = !!target.getAttribute("data-person-id")
      ? target.getAttribute("data-name")
      : target.getAttribute("data-email");
    const isPrimary = this.isPrimaryRecipient(target);
    this.tagify.addTags([
      {
        value: displayValue,
        email: target.getAttribute("data-email"),
        id: target.getAttribute("data-person-id"),
        class: this.recipientClass(target, isPrimary),
      },
    ]);

    const form = this.element.closest("form");
    const formData = new FormData(form);
    const templateId = formData.get("pipeline_email[email_template_id]");

    if (isPrimary && templateId.length > 0) {
      this.personalizeEmailBody();
    }

    this.hideResults();
  }

  isPrimaryRecipient(target) {
    return (
      this.inputTarget.name === "pipeline_email[to]" &&
      this.primaryRecipientIndex === -1 &&
      !!target.getAttribute("data-person-id")
    );
  }

  recipientClass(target, isPrimary) {
    let cssClass = "";
    if (isPrimary) {
      cssClass = "primary";
      this.setPrimaryRecipient(this.tagify.value.length, target.getAttribute("data-person-id"));
    }
    if (this.tagify.value.length > 0 && this.primaryRecipientIndex > -1) {
      this.multipleRecipientsMessageTarget.classList.remove("hidden");
    }
    return cssClass;
  }

  setPrimaryRecipient(index, personId, alreadyPersonalized = false) {
    this.primaryRecipientIndex = index;
    this.primaryPersonTarget.value = personId;
    const form = this.element.closest("form");
    const formData = new FormData(form);
    const templateId = formData.get("pipeline_email[email_template_id]");

    if (templateId && this.tagify.value.length > 0 && !alreadyPersonalized) {
      this.personalizeEmailBody();
    }
    this.dispatch("setPrimaryPerson", { detail: { personId: personId } });
  }

  async personalizeEmailBody() {
    if (!this.hasChangeTemplatePathValue) return;

    const form = this.element.closest("form");
    const formData = new FormData(form);

    formData.delete("pipeline_email[body]");
    formData.delete("pipeline_email[rich_subject]");
    formData.delete("pipeline_email[generative_enabled]");
    formData.delete("pipeline_email[generative_language]");
    formData.delete("pipeline_email[generative_directive]");

    // There is a race condition when initially adding a primary recipient, where the tag is added but the input value is not yet updated.
    // I couldn't find a way to deterministically wait for the input value to be updated, so just manually set it here based on the tag.
    if (!formData.get("pipeline_email[to]") && formData.get("pipeline_email[person_id]") == this.tagify.value[0]?.id) {
      formData.set("pipeline_email[to]", this.tagify.value[0].email);
    }

    await post(this.changeLoadingPathValue, {
      responseKind: "turbo-stream",
    });

    await post(this.changeTemplatePathValue, {
      body: formData,
      responseKind: "turbo-stream",
    });
  }
}
