import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { epoch: Number };

  connect() {
    this.convertTime();
  }

  convertTime() {
    const date = new Date(this.epochValue * 1000);
    const time = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // 24-hour format
    });
    this.element.textContent = time;
  }
}
