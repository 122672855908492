import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["vehicleType"];

  connect () {
    this.typeChanged();
  }

  typeChanged() {
    this.hideAllTypeInputs();

    switch (this.vehicleTypeTarget.value) {
      case "ira":
        this.showIraFields();
        break;
      case "individual":
        this.showIndividualFields();
        break;
      case "married_couple":
        this.showIndividualFields();
        break;
      case "joint_tenants":
        this.showIndividualFields();
        break;
      case "entity":
        this.showEntityFields();
        break;
      case "partnership":
        this.showEntityFields();
        break;
      case "corporation":
        this.showEntityFields();
        break;
      case "trust":
        this.showTrustFields();
        break;
    }
  }

  hideAllTypeInputs() {
    this.hideIraFields();
    this.hideIndividualFields();
    this.hideEntityFields();
    this.hideTrustFields();
  }

  hideIraFields() {
    document.getElementById("form-ira-fields").classList.add("hidden");
  }

  hideIndividualFields() {
    document.getElementById("form-individual-fields").classList.add("hidden");
  }

  hideEntityFields() {
    document.getElementById("form-entity-fields").classList.add("hidden");
  }

  hideTrustFields() {
    document.getElementById("form-trust-fields").classList.add("hidden");
  }

  showIraFields() {
    document.getElementById("form-ira-fields").classList.remove("hidden");
  }

  showIndividualFields() {
    document.getElementById("form-individual-fields").classList.remove("hidden");
  }

  showEntityFields() {
    document.getElementById("form-entity-fields").classList.remove("hidden");
  }

  showTrustFields() {
    document.getElementById("form-trust-fields").classList.remove("hidden");
  }
}
