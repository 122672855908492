import { Controller } from "stimulus";

export default class extends Controller {
  static values = { highlightClass: String, afterHighlightClass: String };

  connect() {
    this.highlightClass = this.highlightClassValue || "bg-warning-100";
    this.afterHighlightClass = this.afterHighlightClassValue || "bg-white";
    this.highlight();
  }

  highlight() {
    if (this.isHighlighted()) return;

    this.element.classList.add(this.highlightClass);
    this.element.classList.remove(this.afterHighlightClass);

    setTimeout(() => {
      this.element.classList.add(
        "transition-colors",
        "duration-1000",
        "ease-linear"
      );
    }, 0);

    setTimeout(() => {
      this.element.classList.add(this.afterHighlightClass);
      this.element.classList.remove(this.highlightClass);
    }, 1000);

    setTimeout(() => {
      this.element.classList.remove(
        "transition-colors",
        "duration-1000",
        "ease-linear"
      );
    }, 3000);

    this.element.dataset.highlighted = this.highlightedIdentifier;
  }

  isHighlighted() {
    return this.element.dataset.highlighted == this.highlightedIdentifier;
  }

  get highlightedIdentifier() {
    return "highlighted";
  }
}
