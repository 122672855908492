import { Controller } from "stimulus";
import { isEqualSets } from "../utils/setEquality";

export default class extends Controller {
  static targets = [
    "emailBody",
    "automationFormContainer",
    "presentAutomation",
    "emptyAutomation",
    "disabledAutomationContainer",
    "enabledAutomationContainer",
    "disabledAutomationToggle",
  ];
  static values = {
    existingAutomations: Object,
    pipelineEmailId: Number,
    formModel: String,
  };

  initializeTrixEvents() {
    this.initializeAutomations();
    this.disabledAutomationToggleTarget.addEventListener("change", this.updateIgnoredAutomationContainer.bind(this));
    this.updateIgnoredAutomationContainer();

    this.emailBodyTarget.addEventListener("trix-change", this.refreshAutomationLinks.bind(this));

    const updatedAutomationsDataAttribute = this.emailBodyTarget.getAttribute("data-email-automation");

    if (updatedAutomationsDataAttribute) {
      this.existingAutomationsValue = JSON.parse(updatedAutomationsDataAttribute);
      this.initializeAutomations();
      const ignoreAutomation = this.emailBodyTarget.getAttribute("data-ignore-automation");
      this.disabledAutomationToggleTarget.checked = ignoreAutomation == "true";
      this.updateIgnoredAutomationContainer();
    }
  }

  emailBodyTargetConnected() {
    this.emailBodyTarget.addEventListener("trix-initialize", this.initializeTrixEvents.bind(this));
  }

  initializeAutomations() {
    this.automationsInBody = {
      variables: new Set(this.existingAutomationsValue.all_variables),
      links: new Set(this.existingAutomationsValue.all_links),
      data_room_folders: new Set(this.existingAutomationsValue.all_data_room_folders),
      questionnaires: new Set(this.existingAutomationsValue.all_questionnaires),
    };
    this.currentAutomationsWithAssignedStages = {
      links: this.existingAutomationsValue.links,
      variables: this.existingAutomationsValue.variables,
      data_room_folders: this.existingAutomationsValue.data_room_folders,
      questionnaires: this.existingAutomationsValue.questionnaires,
    };

    this.refreshAutomationLinks();
    this.renderForm();
  }

  refreshAutomationLinks() {
    if (!this.hasEmailBodyTarget) {
      return;
    }

    const hrefLinks = this.#parseHrefFromContent(this.emailBodyTarget);
    const attachments = this.emailBodyTarget.editor.getDocument().getAttachments();
    const dataRoomsInText = this.getDataRoomAttachmentNamesFromTrix(attachments);
    const variablesInText = this.getVariableAttachmentNamesFromTrix(attachments);
    const questionnairesInText = this.getQuestionnaireAttachmentNamesFromTrix(attachments);
    const currentAutomationsInBody = {
      links: new Set(hrefLinks),
      variables: new Set(variablesInText),
      data_room_folders: new Set(dataRoomsInText),
      questionnaires: new Set(questionnairesInText),
    };

    if (this.checkIfLinksHaveChanged(currentAutomationsInBody)) {
      this.automationsInBody = currentAutomationsInBody;
      [("links", "variables", "data_room_folders", "questionnaires")].forEach((automationType) => {
        this.automationsInBody[automationType].forEach((automation) => {
          this.currentAutomationsWithAssignedStages[automationType][automation] =
            this.currentAutomationsWithAssignedStages[automationType][automation] || null;
        });
      });
      this.renderForm();
    }
  }

  #parseHrefFromContent(element) {
    const text = element.editor.getDocument().toString();
    const urlRegex = /(https?:\/\/[^\(\)\s]+)/g;
    let urlsInText = text.match(urlRegex) || [];

    const parser = new DOMParser();
    const doc = parser.parseFromString(element.value, "text/html");
    const links = doc.querySelectorAll("a");
    const urlsInHref = Array.from(links).map(function (link) {
      // Remove text value from urlsInText
      urlsInText = urlsInText.filter((url) => url !== link.textContent.trim());
      return link.getAttribute("href");
    });
    return urlsInText.concat(urlsInHref).filter((url) => this.#isValidUrl(url));
  }

  #isValidUrl(urlString) {
    const inputElement = document.createElement("input");
    inputElement.type = "url";
    inputElement.value = urlString;
    const valid = inputElement.checkValidity();
    inputElement.remove();
    return valid;
  }

  checkIfLinksHaveChanged(currentAutomations) {
    return !(
      isEqualSets(currentAutomations.links, this.automationsInBody.links) &&
      isEqualSets(currentAutomations.variables, this.automationsInBody.variables) &&
      isEqualSets(currentAutomations.data_room_folders, this.automationsInBody.data_room_folders)
    );
  }

  renderForm() {
    this.showHidePlaceholder();
    this.rowCounter = 0;
    const variableRows = Array.from(this.automationsInBody["variables"]).map((linkName) =>
      this.buildRow("variables", linkName, this.currentAutomationsWithAssignedStages.variables[linkName])
    );
    const linkRows = Array.from(this.automationsInBody["links"]).map((linkName) =>
      this.buildRow("links", linkName, this.currentAutomationsWithAssignedStages.links[linkName])
    );
    const dataRoomFolderRows = Array.from(this.automationsInBody["data_room_folders"]).map((dataRoomFolderName) =>
      this.buildRow(
        "data_room_folders",
        dataRoomFolderName,
        this.currentAutomationsWithAssignedStages.data_room_folders[dataRoomFolderName]
      )
    );
    const questionnaireRows = Array.from(this.automationsInBody["questionnaires"]).map((questionnaireName) =>
      this.buildRow(
        "questionnaires",
        questionnaireName,
        this.currentAutomationsWithAssignedStages.questionnaires[questionnaireName]
      )
    );
    const combinedAutomationRows = variableRows
      .concat(linkRows)
      .concat(dataRoomFolderRows)
      .concat(questionnaireRows)
      .join("");
    this.automationFormContainerTarget.innerHTML = combinedAutomationRows;
  }

  showHidePlaceholder() {
    if (
      this.automationsInBody["variables"].size +
        this.automationsInBody["data_room_folders"].size +
        this.automationsInBody["questionnaires"].size +
        this.automationsInBody["links"].size >
      0
    ) {
      this.emptyAutomationTarget.classList.add("hidden");
      this.presentAutomationTarget.classList.remove("hidden");
    } else {
      this.presentAutomationTarget.classList.add("hidden");
      this.emptyAutomationTarget.classList.remove("hidden");
    }
  }

  buildRow(automationType, linkName, selectedStageId) {
    const optionRows = this.existingAutomationsValue.all_stages.map((stage) => {
      const isSelectedTag = stage.id == selectedStageId ? 'selected="selected"' : "";
      return `<option value="${stage.id}" ${isSelectedTag}>${stage.name}</option>`;
    });

    let shownLinkName = linkName;
    // FIXME: Handles case of proteceted variables without root prefix adds prospectct to the front
    if (automationType === "variables") {
      let normalizedVariableName = shownLinkName.split(".");
      if (normalizedVariableName.length === 1 || normalizedVariableName[0] === "") {
        normalizedVariableName.unshift("prospect");
        normalizedVariableName = normalizedVariableName.filter((part) => part !== "");
      }
      shownLinkName = normalizedVariableName.join(".");
    }
    return `<tr class="${this.rowBackground()}">
          <td class="max-w-0"><div class="flex items-center gap-1"><i class="ph-link-simple text-primary-400 text-md"></i><div class="text-dark-700 truncate">${shownLinkName}</div></div></td>
          <td>
            <select
             data-automation-type=${automationType}
             data-link-name=${linkName}
             data-action="change->pipeline-emails-automations#updateAutomation"
             class="form-control"
             name="${this.formModelValue}[[automation][${automationType}][${linkName}]]">
              <option value="">== Do Nothing ==</option>
              ${optionRows}
            </select>
          </td>
        </tr>`;
  }

  rowBackground() {
    this.rowCounter += 1;
    const odd = this.rowCounter % 2;
    return odd ? "bg-white" : "bg-dark-50";
  }

  updateAutomation(event) {
    const automationType = event.target.dataset.automationType;
    const linkName = event.target.dataset.linkName;
    this.currentAutomationsWithAssignedStages[automationType][linkName] = event.target.value;
  }

  updateIgnoredAutomationContainer(event) {
    const enabled = this.disabledAutomationToggleTarget.checked;

    if (enabled) {
      this.disabledAutomationContainerTarget.classList.remove("hidden");
      this.enabledAutomationContainerTarget.classList.add("hidden");
    } else {
      this.disabledAutomationContainerTarget.classList.add("hidden");
      this.enabledAutomationContainerTarget.classList.remove("hidden");
    }
  }

  parseVariableNameFromAttachmentHtml(attachmentHtml) {
    const variableData = this.parseAttachmentHtmlData(attachmentHtml);
    return variableData.variableName;
  }

  parseDataRoomNameFromAttachmentHtml(attachmentHtml) {
    const dataRoomData = this.parseAttachmentHtmlData(attachmentHtml);
    return dataRoomData.dataRoomName;
  }

  parseQuestionnaireNameFromAttachmentHtml(attachmentHtml) {
    const questionnaireData = this.parseAttachmentHtmlData(attachmentHtml);
    return questionnaireData.questionnaireName;
  }

  getVariableAttachmentNamesFromTrix(attachments) {
    const variableAttributes = attachments.filter(this.attachmentIsUrlVariable.bind(this)).map((attachment) => {
      return attachment.getAttributes();
    });
    return variableAttributes.map((attributes) => {
      return this.parseVariableNameFromAttachmentHtml(attributes.content);
    });
  }

  getDataRoomAttachmentNamesFromTrix(attachments) {
    const dataRoomAttributes = attachments.filter(this.attachmentIsDataRoom.bind(this)).map((attachment) => {
      return attachment.getAttributes();
    });
    return dataRoomAttributes.map((attributes) => {
      return this.parseDataRoomNameFromAttachmentHtml(attributes.content);
    });
  }

  getQuestionnaireAttachmentNamesFromTrix(attachments) {
    const questionnaireAttributes = attachments.filter(this.attachmentIsQuestionnaire.bind(this)).map((attachment) => {
      return attachment.getAttributes();
    });
    return questionnaireAttributes.map((attributes) => {
      return this.parseQuestionnaireNameFromAttachmentHtml(attributes.content);
    });
  }

  attachmentIsUrlVariable(attachment) {
    const attachmentHtml = attachment.getAttributes()["content"];
    if (attachmentHtml?.includes("variable_attachment")) {
      const variableData = this.parseAttachmentHtmlData(attachmentHtml);
      return variableData.variableIsUrl == "true";
    } else {
      return false;
    }
  }

  attachmentIsDataRoom(attachment) {
    const attachmentHtml = attachment.getAttributes()["content"];
    return attachmentHtml?.includes("attached-actiontext-data-room");
  }

  attachmentIsQuestionnaire(attachment) {
    const attachmentHtml = attachment.getAttributes()["content"];
    return attachmentHtml?.includes("attached-actiontext-questionnaire");
  }

  parseAttachmentHtmlData(attachmentHtml) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(attachmentHtml, "text/html");
    return doc.querySelector("span").dataset;
  }
}
