import { Controller } from "stimulus";
import Trix from "trix";
import { hideAll as tippyHideAll } from "tippy.js";

export default class extends Controller {
  static targets = [
    "emailForm",
    "emailSenderToText",
    "emailSenderTo",
    "emailSubject",
    "emailBody",
    "emailSenderFromAddress",
    "emailConnectedMessage",
    "emailNotConnectedClass",
    "emailNotConnectedMessage",
    "uploadFile",
    "uploadedFilesContainer",
    "prospectVars",
  ];

  static values = {
    pipelineProspectId: Number,
    fetchVariableValueUrl: String,
  };

  focusElement = "";
  initializing = true;

  connect() {
    this.prepareForm();

    this.uploadFileTarget.addEventListener("change", this.handleFileUpload.bind(this));

    document.addEventListener("turbo:submit-end", async (e) => {
      const responseStatus = e.detail.fetchResponse?.response?.status;
      const responseUrl = e.detail.fetchResponse?.response?.url;

      if (
        (responseStatus == 200 || responseStatus == 204) &&
        (responseUrl.includes("update_variable_actiontext") ||
          responseUrl.includes("update_policy_protected_variable_actiontext"))
      ) {
        await this.updateVariableColorAndValues(e);
      }
    });

    if (this.hasEmailSenderFromAddressTarget) this.fromEmailOptionChange();
    this.initializing = false;
  }

  disconnect() {
    this.uploadFileTarget.removeEventListener("change", this.handleFileUpload.bind(this));
  }

  prepareForm() {
    if (this.hasEmailSenderToTextTarget && this.emailSenderToTextTarget.value != "") {
      this.emailSenderToTextTarget.classList.remove("hidden");
    }
  }

  emailBodyTargetConnected(e) {
    this.focusElement = this.emailBodyTarget;
    if (
      this.hasEmailSenderToTarget &&
      this.emailSenderToTarget.previousElementSibling?.querySelector("span[contenteditable]")
    ) {
      setTimeout(
        () => this.emailSenderToTarget.previousElementSibling.querySelector("span[contenteditable]").focus(),
        400
      );
    }
    if (!this.hasProspectVarsTarget || !this.prospectVarsTarget.textContent) return;

    const variables = JSON.parse(this.prospectVarsTarget.textContent);
    this.findAndReplaceTrixVariables(this.emailSubjectTarget.editor.getDocument(), variables);
    this.findAndReplaceTrixVariables(this.emailBodyTarget.editor.getDocument(), variables);

    if (
      !this.hasEmailSenderToTarget ||
      !this.emailSenderToTarget.previousElementSibling?.querySelector("span[contenteditable]")
    )
      return;

    this.emailSenderToTarget.previousElementSibling.querySelector("span[contenteditable]").focus();
  }

  fromEmailOptionChange(e) {
    const fromAddress = this.emailSenderFromAddressTarget;
    const connectedMessage = this.emailConnectedMessageTarget;
    const notConnectedMessage = this.emailNotConnectedMessageTarget;
    const selectedFromAddress = fromAddress.options[fromAddress.selectedIndex].text;

    if (selectedFromAddress.includes("Assigned User (or Account Owner)")) {
      connectedMessage.classList.add("hidden");
      notConnectedMessage.classList.add("hidden");
    } else if (selectedFromAddress.includes("(Connected)")) {
      connectedMessage.classList.remove("hidden");
      notConnectedMessage.classList.add("hidden");
    } else {
      connectedMessage.classList.add("hidden");
      notConnectedMessage.classList.remove("hidden");
    }
  }

  findAndReplaceTrixVariables(trixDocument, variables) {
    trixDocument.getAttachments().forEach((attachment) => {
      let content = attachment.getAttribute("content");
      if (content?.includes("data-variable-name")) {
        const variable = variables.find((variable) => variable.name == content.match(/data-variable-name="(.*?)"/)[1]);
        if (variable && variable.value) {
          attachment.setAttributes({
            content: content.replace(` ${variable.name}`, ` ${variable.value}`),
          });
        } else {
          attachment.setAttributes({
            content: content.replace("bg-primary-50 text-primary-600", "bg-red-50 text-red-600"),
          });
        }
      }
    });
  }

  changeFocusElement(ele) {
    if (ele.target.dataset.pipelineEmailsFormTarget == "emailSubject") {
      this.focusElement = this.emailSubjectTarget;
    } else {
      this.focusElement = this.emailBodyTarget;
    }
  }

  toEmailOptionChange(ele) {
    if (ele.target.value == "external_person") {
      this.emailSenderToTextTarget.classList.remove("hidden");
      this.emailSenderToTextTarget.focus();
    } else {
      this.emailSenderToTextTarget.classList.add("hidden");
    }
  }

  insertHtmlAttachmentAtCursorForRichText(htmlString, sgid, variable_name, editor) {
    editor.recordUndoEntry("Insert variable");
    const attachment = new Trix.Attachment({
      content: htmlString,
      sgid: sgid,
      variable_name: variable_name,
    });
    editor.insertAttachment(attachment);
  }

  insertAtCursorForSubject(insertValue) {
    const startPosition = this.emailSubjectTarget.selectionStart;
    const endPosition = this.emailSubjectTarget.selectionEnd;

    this.emailSubjectTarget.value =
      this.emailSubjectTarget.value.substring(0, startPosition) +
      insertValue +
      this.emailSubjectTarget.value.substring(endPosition, this.emailSubjectTarget.value.length);

    this.emailSubjectTarget.focus();
    this.emailSubjectTarget.selectionStart = startPosition + insertValue.length;
    this.emailSubjectTarget.selectionEnd = endPosition + insertValue.length;
  }

  async insertAtCursor(event) {
    event.preventDefault();
    event.target.classList.add("disabled");
    const form = event.target.closest("form");
    const formData = new FormData(form);
    const email_from_actor = formData.get("pipeline_email[email_from_actor]");
    const baseUrl = event.target.dataset.fetchRenderedVariableUrl;
    const hasParams = baseUrl.includes("?");
    const separator = hasParams ? "&" : "?";
    let url = `${baseUrl}${separator}email_from_actor=${email_from_actor}`;
    const primaryRecipient = form.querySelector(".tagify__tag.primary");
    if (primaryRecipient) {
      url += `&person_id=${primaryRecipient.id}`;
    }

    const ajaxFetchedVariableHtmlString = await this.fetchRenderedVariable(url);

    this.insertHtmlAttachmentAtCursorForRichText(
      ajaxFetchedVariableHtmlString.content,
      ajaxFetchedVariableHtmlString.sgid,
      ajaxFetchedVariableHtmlString.variable_name,
      this.focusElement.editor
    );
    this.triggerKeyupEvent();
    event.target.classList.remove("disabled");
  }

  fetchRenderedVariable(url) {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        url: url,
        type: "GET",
        success: (response) => resolve(response),
        error: (response) => reject(response),
      });
    });
  }

  triggerKeyupEvent() {
    const keyupEvent = new KeyboardEvent("keyup", {
      bubbles: true,
      cancelable: true,
      keyCode: 13,
    });
    this.emailBodyTarget.dispatchEvent(keyupEvent);
  }

  handleFileUpload(event) {
    const uploadedFilenames = Array.from(event.target.files).map((file) => file.name);

    if (uploadedFilenames.length) {
      const uploadedFileElements = uploadedFilenames.map((filename) => {
        return `<span>${filename}</span>`;
      });
      this.uploadedFilesContainerTarget.innerHTML = "Uploaded Files: " + uploadedFileElements;
    } else {
      this.uploadedFilesContainerTarget.innerHTML = "";
    }
  }

  getVariableFromEvent(event) {
    return event.target.dataset.variable;
  }

  fetchAndReplaceVariable(variable) {
    if (!this.pipelineProspectIdValue) return;

    const newUrl = new URL(this.fetchVariableValueUrlValue);
    const params = new URLSearchParams(newUrl.search);
    params.append("pipeline_prospect_id", this.pipelineProspectIdValue);
    params.append("variable", variable);
    newUrl.search = params.toString();

    return new Promise((resolve, reject) => {
      Rails.ajax({
        url: newUrl.toString(),
        type: "GET",
        success: (response) => resolve(response.value),
        error: (response) => reject(response),
      });
    });
  }

  async updateVariableColorAndValues(event) {
    const variableId = this.getVariableIdFromVariablesUrl(event.detail.fetchResponse.response.url);

    await event.detail.fetchResponse.response
      .clone()
      .text()
      .then((value) => {
        let trixDocument = this.emailBodyTarget.editor.getDocument();

        trixDocument.getPieces().forEach((piece) => {
          this.updateVariableColorAndValue(piece, variableId, value);
        });

        this.emailBodyTarget.editor.setSelectedRange([0, 0]);

        trixDocument = this.emailSubjectTarget.editor.getDocument();

        trixDocument.getPieces().forEach((piece) => {
          this.updateVariableColorAndValue(piece, variableId, value);
        });

        this.emailSubjectTarget.editor.setSelectedRange([0, 0]);

        document.activeElement?.blur();
      });
  }

  updateVariableColorAndValue(piece, variableId, value) {
    if (!(piece instanceof Trix.AttachmentPiece)) {
      return;
    }

    if (!piece.attachment.getContent().includes(`variable_attachment_${variableId}`)) {
      return;
    }

    piece.attachment.setAttributes({
      ...piece.attachment.getAttributes(),
      content: piece.attachment
        .getContent()
        .replace(
          new RegExp(`id="variable_value_${variableId}">(.*?)<\/span>`, "g"),
          `id="variable_value_${variableId}">${value}<\/span>`
        )
        .replace(new RegExp("-red-", "g"), "-primary-"),
    });

    tippyHideAll();
  }

  getVariableIdFromVariablesUrl(variablesUrl) {
    const match =
      variablesUrl.match(/\/(\d+)\/update_variable_actiontext/) ||
      variablesUrl.match(/\/(\d+)\/update_policy_protected_variable_actiontext/);

    const number = parseInt(match[1]);
    return number;
  }
}
