import { Controller } from "@hotwired/stimulus"
import Tagify from "@yaireo/tagify"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["input"]

  async connect() {
    await this.getUsersJson();
    this.tagify = new Tagify(this.inputTarget, {
      whitelist: window.mentionsUsersList.map(user => ({ value: user.id, name: user.name })),
      enforceWhitelist: true,
      dropdown: {
        enabled: 1,
        classname: "users-list",
        searchKeys: ["name"],
        mapValueTo: "name"
      },
      tagTextProp: 'name',
      autoComplete: { tabKey: true }
    });
  }

  clearInput(event) {
    this.tagify.removeAllTags()
  }

  async getUsersJson() {
    if (window.mentionsUsersList !== undefined) return;

    const res = await get(`/mentions.json`)
    const json = await res.json
    window.mentionsUsersList = json
  }
}
