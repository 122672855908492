import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["data"];

  connect() {
    this.originalData = this.dataTarget.innerHTML.trim();
    this.hide();
  }

  toggle() {
    this.hidden ? this.reveal() :this.hide();
  }

  reveal() {
    this.hidden = false;
    this.dataTarget.innerHTML = this.originalData;
  }

  hide() {
    this.hidden = true;
    this.dataTarget.innerHTML = this.originalData.replace(/./g, "*");
  }
}
