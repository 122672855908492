import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["postList", "sidebar"];

  async showThread(event) {
    if (!event.target.closest("a")) {
      const url = event.currentTarget.dataset.postUrl;
      if (event.ctrlKey || event.metaKey) {
        this.openPostInNewTab(url);
      } else {
        await get(url, { responseKind: "turbo-stream" });
        this.toggleSidebar(true);
        if (typeof closeSlideover !== "undefined") closeSlideover();
      }
    }
  }

  openPostInNewTab(url) {
    const link = document.createElement("a");
    link.href = url.replace(/\/show_slideover/, "");
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  toggleSidebar(showSidebar) {
    if (showSidebar === true) {
      document.body.classList.add("post-side-thread-active");
    } else {
      document.body.classList.remove("post-side-thread-active");
    }
  }
}
