import { Controller } from "stimulus";

export default class extends Controller {
  static values = { regex: String };

  connect() {
    this.observePostList();
  }

  observePostList() {
    let debounceTimer;
    const observer = new MutationObserver((mutations) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        mutations.forEach((mutation) => {
          if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
            const newElement = mutation.target.lastElementChild;
            if (newElement) {
              this.scrollToElement(newElement);
            }
          }
        });
      }, 200);
    });

    observer.observe(this.element, { childList: true });
  }

  scrollToElement(element) {
    setTimeout(() => {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }, 200);
  }
}
