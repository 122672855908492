import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.editorElement = this.element.querySelector("trix-editor");
    this.addToBody = this.data.get("add-to-body-value");

    this.editorElement.addEventListener("trix-initialize", () => this.initializeTrix());
  }

  initializeTrix() {
    if (this.addToBody) {
      this.insertTextToEditor(this.addToBody);
    }
  }

  insertTextToEditor(text) {
    const editor = this.editorElement.editor;
    const anchorTag = `<a href="${text}">${text}</a>`;

    editor.setSelectedRange([editor.getDocument().getLength(), editor.getDocument().getLength()]);

    editor.insertHTML(anchorTag);
  }
}
